/* .mainapp{
    display:flex;
    flex-direction: column;
} */
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

*{
    scroll-behavior: smooth;
    font-family:'Mulish', sans-serif;
}

::-webkit-scrollbar {
    width: 7px;
    scrollbar-width: thin;
}

::-webkit-scrollbar-track {
    background: #000000;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10%;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}