body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(0, 0, 0);
}


.grave-navbar {
    display: flex;
    height: 4rem;
    align-items: center;
    justify-content: center;
}

.grave-navbar-name {
    /* background-color: red; */
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: white;
    font-weight: bolder;
    font-size: 20px;

}


.grave-navbar-name img {
    border-radius: 50%;
    width: 2rem;
    height: auto;
    margin-right: 10px;
}

.grave-navbar-name:hover {
    font-family: Arial, Helvetica, sans-serif;
    color: whitesmoke;
    cursor: pointer;
}