
.home_section {
    display: flex;
    flex-direction: column;
}

.gimage {
    display: flex;

    align-items: center;
    justify-content: center;


}

.gimage img {
    width: 35vh;
    border-radius: 50%;
    box-shadow: 0px 5px 95px rgb(86, 61, 133);
    transform: translateY(50px);
    opacity: 85%;

}

.mainintro {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
    align-items: center;

}

.mainintro>:nth-child(2) {
    transform: translateY(100px);
    font-size: 1rem;
    
    text-shadow: 1px 1px 1px rgb(172, 167, 167);

}

.mainintro:hover {
    cursor: default;
    
    /* text-shadow: 1px 1px 1px rgb(172, 167, 167); */
}

.stroketext_intro {
    color: transparent;
    /* text-shadow: 1px 1px 1px rgb(172, 167, 167); */
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: rgb(211, 208, 208);
    gap: 0rem;
    text-transform: uppercase;
    font-size: 4rem;
    font-weight: bold;
    transform: translateY(95px);
}

.linkbuttons {
    display: flex;
    transform: translateY(115px);
    gap: 1rem;
}


@media only screen and (max-width: 600px) {
    .mainintro>:nth-child(2) {
        transform: translateY(100px);
        font-size: 0.8rem;

    }
    .stroketext_intro {
        font-size: 3.8rem;
        text-shadow: 2px 3px 1px rgb(172, 167, 167);
    }
    
    .gimage img {
        width: 30vh;
        box-shadow: 0px 5px 90px rgb(86, 61, 133);
    }
    
}